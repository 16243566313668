@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&family=Zilla+Slab:wght@500&display=swap);
@font-face {
    font-family: 'Kelson Sans Regular';
    src: url(/static/media/KelsonSans-Regular.ac031068.woff2) format('woff2'),
        url(/static/media/KelsonSans-Regular.4d7f21b0.woff) format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kelson Sans Bold';
    src: url(/static/media/KelsonSans-Bold.816e232c.woff2) format('woff2'),
        url(/static/media/KelsonSans-Bold.1442bc03.woff) format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Kelson Sans Light';
    src: url(/static/media/KelsonSans-Light.cb5a8f45.woff2) format('woff2'),
        url(/static/media/KelsonSans-Light.b36cde26.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
.full-height{
    height: 100vh!important;
}
.left-content{
    margin-top: -109px!important;
}
.brand-image{
    width:40px!important;
}
.brand-item{
    padding: 30px;
    -webkit-filter:grayscale(1);
            filter:grayscale(1);
    text-decoration: none!important;
}
.brand-item:hover{
    border-radius: 6px!important;
    background-color: #F1F1F1!important;
    color: #E2231A!important;
    -webkit-filter:grayscale(0);
            filter:grayscale(0)
}
.brand-icon{
    width: 60px!important;
}

.brand {
    width: 240px!important;
    position: fixed;
    padding: 35px 0 0 35px!important;
    z-index: 1!important;
}
.no-padding{
    padding: 0px!important;
}

.primary-bg {
    background-color: #E2231A;
}

.white-bg {
    background-color: #FFFFFF!important;
}

.dark-red-color {
    color: #93100A;
}

h1 {
    font-family: 'Zilla Slab', serif;
    font-size: 64px !important;
    letter-spacing: -3px;
}

h3 {
    line-height: 48px!important;
}

.red-solid-btn {
    width: 100%!important;
    border: #d52219!important;
    background-color: #E2231A !important;
    padding: 16px 24px 13px 20px !important;
    font-family: "Kelson Sans Regular" !important;
    transition-property: color;
    transition-duration: 0.4s;
    transition-timing-function: ease-in;
}

.red-solid-btn:hover {
    width: 100%!important;
    background-color: #c00f06 !important;
}

.light {
    font-family: 'Kelson Sans Light' !important;
}

p {
    font-family: "Kelson Sans Regular" !important;
}

.form-control {
    height: 50px !important;
    padding-top: 12px !important;;
    font-family: "Kelson Sans Regular" !important;
    border-color: #F2F2F2 !important;
    color: #262729 !important;
}

.form-control::-webkit-input-placeholder {
    color: #C2C2C2 !important;
    font-size: 14px !important;
}

.form-control::placeholder {
    color: #C2C2C2 !important;
    font-size: 14px !important;
}

.form-label {
    font-family: "Kelson Sans Regular" !important;
    font-size: 13px;
}

.form-select {
    height: 50px;
    padding-top: 12px !important;
    ;
    font-family: "Kelson Sans Regular" !important;
    color: #262729 !important;
}

.nav {
    font-family: "Kelson Sans Regular" !important;
}

.nav-link {
    padding: 20px 20px 15px 20px !important;
    color: #c1c1c1!important;
    background-color: #FFFFFF!important;
    border-radius: 0px !important;
    border-bottom: 2px solid #c1c1c1 !important;
    -webkit-filter:grayscale(1);
            filter:grayscale(1);
}
.nav-link.active{
    color: #E2231A!important;
    background-color: #FFFFFF !important;
    border-bottom: 2px solid #E2231A !important;
    border-radius: 0px !important;
    -webkit-filter:grayscale(0);
            filter:grayscale(0);
}
.nav-link .nav-item ul li{
    margin-left: 10px!important;
}
.nav-item{
    color:#E2231A!important;
}
.nav-link:hover {
    /* background-color: #E2231A !important; */
    -webkit-filter:grayscale(0);
            filter:grayscale(0);
    border-bottom: 2px solid #E2231A !important;
    color: #E2231A !important
}
@media only screen and (max-width: 992px) {
    .brand{
        position: fixed!important;
        width:100%!important;
        background-color: #E2231A!important;
        text-align: center;
        padding: 35px 35px 35px 35px!important;
    }
    .logo{
        width: 240px;
    }
    .red-solid-btn{
        width: 100%!important;
    }
    .left-content{
        margin-top: 0px!important;
    }
    h3{
        font-size: 24px!important;
        line-height: 34px!important;
    }
}
